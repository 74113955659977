import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import { FloatingDisclaimer } from '~/common/components/ui-elements';
import '~/styles/main.scss';

interface TooltipProps {
  disclaimerText: string;
}
const Tooltip = (props: TooltipProps) => {
  const { disclaimerText } = props;
  return (
    <FloatingDisclaimer noWrapperElement disclaimer={disclaimerText}>
      {({ renderTrigger }) => renderTrigger()}
    </FloatingDisclaimer>
  );
};

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer;
global.TooltipMacro = Tooltip;
